
import { Component } from "vue-property-decorator";
//import UserTaskController from "@/http/controllers/UserTaskController";
import UserTaskState from "@/models/UserTaskState";
import UserTaskRecord from "@/components/userTasks/UserTaskRecord.vue";
import UserTaskBase from "./UserTasksBase";

//const controller = new UserTaskController();

@Component({
    components: {
        "usertask-record": UserTaskRecord,
    },
})
export default class OpenUserTasks extends UserTaskBase {
    get states(): UserTaskState[] {
        return [UserTaskState.Finished];
    }

    mounted(): void {
        this.getUserTasks(this.states);
    }
}
